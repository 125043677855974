<template>
    <div class="d-flex align-items-center text-nowrap">
        <div v-if="columnData == 'invite'">
            <a @click="$emit('action', {action: 'invite', rowId: rowId})" class="link">Invite</a>
        </div>
        <div v-else :class="{'status-connected': (columnData == 'Active' || columnData == 'Invite Accepted')}">
            {{columnData}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: String,
    },
}
</script>

<style scoped>

</style>